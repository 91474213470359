import React from 'react';

// Utils
import {createComponent, getPropsByHref} from '../utils';
import {usePreHeaderBgSx, useSubFooterBgSx} from '../hooks';

// Components
import {MDXProvider} from '@mdx-js/react';
import {MDXRenderer} from 'gatsby-plugin-mdx';
import {Base} from '../../ui/layouts';
import {Badge, Box, FlexCol, FlexInline, Heading, Text, TextLink} from '../../ui/components';
import {Footer, Head, Header, PreHeader, SubFooter} from '../components';
import {PageContextProvider} from '../contexts';
import {IconTag} from '../../ui/icons';
import {toTagNHref} from '../utils';

const components = {
    h1: createComponent('h1', Heading, {as: 'h1', level: 2, align: 'center', color: 'textOnDark'}),
    h2: createComponent('h2', Heading, {as: 'h2', level: 3, align: 'center', color: 'textOnDark'}),
    h3: createComponent('h3', Heading, {as: 'h3', level: 4, align: 'center', color: 'textOnDark'}),
    p: createComponent('p', Text, {color: 'textOnDark'}),
    a: createComponent('a', TextLink, ({href}) => ({
        href,
        decoration: 'inverted',
        ...getPropsByHref(href)
    })),
    em: createComponent('em', Box, {as: 'em', sx: {fontStyle: 'italic'}}),
    strong: createComponent('strong', Box, {as: 'strong', sx: {fontWeight: 'bold'}}),
    img: (props) => {
        return null;
    }
};

const MdxArticle = (props) => {
    const {article} = props.pageContext;

    const preHeaderBgSx = usePreHeaderBgSx();
    const subFooterBgSx = useSubFooterBgSx();

    return (
        <PageContextProvider value={props.pageContext}>
            <Head
                meta={{
                    canonical: article.page.pathname,
                    ...article.meta
                }}
            />
            <Base.Container>
                <Base.PreHeader sx={preHeaderBgSx}>
                    <PreHeader/>
                </Base.PreHeader>
                <Base.Header>
                    <Header/>
                </Base.Header>
                <Base.Body
                    px={['sm', 'md']}
                    py={['md', 'md', 'lg']}
                >
                    <FlexCol
                        as="article"
                        width="100%"
                        maxWidth="3xl"
                        alignX="center"
                        alignY="top"
                        space={['md', 'md', 'lg']}
                    >
                        <MDXProvider components={components}>
                            <MDXRenderer>
                                {article.body}
                            </MDXRenderer>
                        </MDXProvider>

                        <FlexCol space={['sm', null, 'md']} alignX="center">
                            <Text color="whisper" align="center">
                                {new Intl.DateTimeFormat('de-DE', {year: 'numeric', month: 'long', day: 'numeric'}).format(new Date(article.meta.createdAt))}
                            </Text>
                            {article.meta.tags.length > 1 ? (
                                <FlexInline space="sm" alignX="center">
                                    <IconTag color="whisper"/>
                                    {article.meta.tags.map(tag => (
                                        <Badge
                                            key={tag}
                                            as="a"
                                            href={toTagNHref(tag)}
                                            bg="whisper"
                                            sx={{
                                                ':hover': {
                                                    bg: 'primary',
                                                    color: 'textOnPrimary'
                                                }
                                            }}
                                        >
                                            {`#${tag}`}
                                        </Badge>
                                    ))}
                                </FlexInline>
                            ) : null}
                        </FlexCol>

                        <Box id="comments" display="none"/>
                    </FlexCol>
                </Base.Body>
                <Base.Footer>
                    <Footer/>
                </Base.Footer>
                <Base.SubFooter sx={subFooterBgSx}>
                    <SubFooter/>
                </Base.SubFooter>
            </Base.Container>
        </PageContextProvider>
    );
};

export default MdxArticle;
