
import React from 'react';

// Utils
import {useColorMode, useThemeUI} from 'theme-ui';
import {darken, lighten} from '@theme-ui/color';

// Components
import {Box, FlexCol} from '../components';

const PreHeader = ({children, ...restProps}) => {
    const [mode] = useColorMode();

    return (
        <Box
            as="header"
            position="relative"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            bg={mode === 'dark' ? darken('header', 0.1) : lighten('header', 0.05)}
            zIndex={3}
            {...restProps}
            sx={{
                transform: 'translate3d(0,0,0)', // Safari fix: not respecting z-index along with sticky
                ...(restProps.sx || {})
            }}
        >
            {children}
        </Box>
    );
};
PreHeader.displayName = 'Basic.PreHeader';

const Header = ({children, ...restProps}) => {
    return (
        <Box
            as="header"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            position={['relative', 'sticky']}
            top={['auto', 0]}
            px={['sm', 'md']}
            minHeight={12}
            zIndex={2}
            bg="header"
            sx={{boxShadow: '0 0 16px 8px rgb(0 0 0 / 50%)'}}
            {...restProps}
        >
            {children}
        </Box>
    );
};
Header.displayName = 'Basic.Header';

const Body = ({children, ...restProps}) => {
    return (
        <Box
            as="main"
            display="flex"
            justifyContent="center"
            flexDirection="row"
            flex="1"
            {...restProps}
            sx={{
                alignSelf: 'stretch',
                ...(restProps.sx || {})
            }}
        >
            {children}
        </Box>
    );
};
Body.displayName = 'Basic.Body';

const Footer = ({children, ...restProps}) => {
    return (
        <Box
            as="footer"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            position="sticky"
            bottom={0}
            px={['sm', 'md']}
            minHeight={12}
            bg="footer"
            zIndex={1}
            sx={{boxShadow: '0 0 16px 8px rgb(0 0 0 / 50%)'}}
            {...restProps}
        >
            {children}
        </Box>
    );
};
Footer.displayName = 'Basic.Footer';

const SubFooter = ({children, ...restProps}) => {
    const [mode] = useColorMode();

    return (
        <Box
            as="footer"
            position="relative"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            bg={mode === 'dark' ? darken('footer', 0.05) : lighten('footer', 0.15)}
            px={['sm', 'md']}
            zIndex={2}
            {...restProps}
            sx={{
                transform: 'translate3d(0,0,0)', // Safari fix: not respecting z-index along with sticky
                ...(restProps.sx || {})
            }}
        >
            {children}
        </Box>
    );
};
SubFooter.displayName = 'Basic.SubFooter';

const Container = ({children}) => {
    // Ensure children array
    children = React.Children.toArray(children);

    const preHeader = children.find(({type}) => type === PreHeader) || null;
    const header = children.find(({type}) => type === Header) || null;
    const body = children.find(({type}) => type === Body) || null;
    const footer = children.find(({type}) => type === Footer) || null;
    const subFooter = children.find(({type}) => type === SubFooter) || null;

    // Theme / style
    const {theme} = useThemeUI();

    const bgGradientStart = darken('header', 0.34)(theme);
    const bgGradientEnd = darken('footer', 0.42)(theme);

    return (
        <FlexCol
            minHeight="100vh"
            space="none"
            alignX="stretch"
            alignY="stretch"
        >
            <FlexCol
                minHeight="100vh"
                space="none"
                alignX="stretch"
                alignY="stretch"
                sx={{
                    background: `linear-gradient(180deg, ${bgGradientStart}, ${bgGradientEnd})`
                }}
            >
                {preHeader}
                {header}
                {body}
                {footer}
            </FlexCol>
            {subFooter}
        </FlexCol>
    );
};

Container.displayName = 'Basic.Container';

const BasicLayout = {
    Container,
    PreHeader,
    Header,
    Body,
    Footer,
    SubFooter
};

export default BasicLayout;
